@media (min-width: 992px){
    .cmt-6 {
        margin-top: 5rem !important;
    }

}




.custom-font {
    /* font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif; */

    font-size: 18px;
}

.wp-block-heading{

    margin-bottom: 0px;
}