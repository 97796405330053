.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 0;
  position: relative;
  width: 560px; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  margin-top: 5rem;
  
  
 
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.no-thanks-text{
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 12px;
  cursor: pointer;
  color: #6B53FF;
 
  
}

.no-thanks-text:hover{
  color:#6a53ffea;
 
}

.dark-mode .modal-content{
  background-color: #0d0e13;
}

.platform--access-layer--wrapper{
  overflow: hidden !important;
  border-radius: 0 !important;
 
}

.no-thanks-text {
  display: none;
}

.no-thanks-text-custom{
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 12px;
  cursor: pointer;
  color: #6B53FF;
 
  
}

.no-thanks-text-custom:hover{
  color:#6a53ffea;
 
}







