
.parallax-layer-react:hover{
transform: translate3d(10.6px, -9px, 0px);
transition:ease-in-out;
transition-duration: 1s;


}

.form-control-text-align{
    text-align: left;
}

.contact-3-margin{
    margin-left: 8rem;
}

.hr-style{
    justify-content: center;
    
}

/* Book Demo Button */
.qxCTlb{
    background-color: #6366f1 !important;
  color: #fff !important;
  padding: .785rem 2rem !important;
  font-size: 1rem !important;
  border-radius: .5rem !important;
}

.qxCTlb:hover{
    background-color: #4044ee !important;
    border-color: #4044ee !important;
}