.padding-custom{
    padding-top: 13rem !important;
}

@media (max-width: 767.98px) { 
    .image-position-custom-md{
        display: flex;
        justify-content: center !important;
    }
 }



 .orbix-badge{
    max-width: 65%;
    height: auto;
}

