.card-body-custom-1{

    min-height: 16rem;
}


.card-body-custom-2{
    min-height: 19.2rem;

}


@media (max-width: 767.98px) { 
    .card-body-custom-1{

        min-height: unset;
    }

    .card-body-custom-2{
        min-height: unset;
    
    }
    

 }