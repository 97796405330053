.background{
  background-color: #0b0f19;
}

.form-control:focus {
    background-color: #2a2838 !important;
    border-color: transparent !important;
    box-shadow: none !important;
    color: white !important;
  }

  .with-border {
    border-right: 2px solid rgba(255, 255, 255, 0.15);
  }

  @media (max-width: 499px) {
    .with-border {
      border-right: none;
      border-bottom: 2px solid rgba(255, 255, 255, 0.15);
    }

    .img-fluid {
        margin-bottom: 1rem;
        margin-top: 1rem;
      }

  }


  @media (max-width: 746px) {
  
      .custom-margin{
        margin-bottom: 0.25rem;
        display: block !important;
      }

  }

