    .custom-linkedin{
        color: #0077B5;
    }

    .custom-btn-linkedin{
        background-color: #fff;
    }

    .custom-btn-linkedin:hover{
        background: rgba(255, 255, 255, 0.80) !important;
        border-color: unset !important;
        box-shadow: none !important;
    }

    .pagination .page-item.active .page-link{
        box-shadow: none;
    }

    .custom-medium{
        color: #000;
    }

    .custom-btn-medium{
        background-color: #fff;
    }

    .custom-btn-medium:hover{
        background: rgba(255, 255, 255, 0.80) !important;
        border-color: unset !important;
        box-shadow: none !important;
    }

    /* newssection.component.css */
    .card-img-top {
        height: 300px; /* Fixed height */
        object-fit: cover; /* Cover the container without losing aspect ratio */
        width: 100%; /* Ensure it covers the width of the container */
    }
    


